import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useState } from "react";

type LinkInfo = {
	type: string,
	link: string
}

const contactUsLinks: LinkInfo[] = [
	{
		type: 'Discord',
		link: 'https://discord.gg/AMrn65tjWt',
	},
	{
		type: 'Twitter',
		link: 'http://x.com/CubeConOfficial',
	},
	{
		type: 'Email Newsletter',
		link: 'https://lp.constantcontactpages.com/sl/4cs2LE3'
	}
];

export const ContactUsItem = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	return <Dropdown isOpen={dropdownOpen} onMouseOver={() => setDropdownOpen(true)} onMouseOut={() => setDropdownOpen(false)} toggle={() => {}} className="navDropdown">
		<DropdownToggle nav caret>Contact Us</DropdownToggle>
		<DropdownMenu dark>
			{contactUsLinks.map(linkInfo => <DropdownItem key={linkInfo.type} onClick={() => window.open(linkInfo.link)}>{linkInfo.type}</DropdownItem>)}
		</DropdownMenu>
	</Dropdown>
}
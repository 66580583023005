import React, { useEffect } from 'react';
import { HeaderSection } from '../components/HeaderSection';
import { Container } from 'reactstrap';
import { CopyrightFooter } from '../components/CopyrightFooter';
import { InfoSidebar } from '../components/InfoSidebar';
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { apiRequest } from '../util/api';
import { useMsal } from '@azure/msal-react';
import { CustomNavigationClient } from '../util/Navigation';

export async function LayoutLoader() {
    const [sponsors, importantdates] = await Promise.all([
        apiRequest("sponsors"),
        apiRequest("importantdates")
    ]);
    return { sponsors, importantdates };
}

export const DefaultLayout: React.FC = () => {
    const mode="cc";

    //Setup the msal provider to use react router navigation
    const navigate = useNavigate();
    const { instance } = useMsal();
    useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        instance.setNavigationClient(navigationClient);
    }, [instance, navigate]);

    return <div className={mode}>
        <HeaderSection />
        <Container className="d-flex flex-column flex-md-row gap-3">
                <div className="flex-grow-1 w-100"><Outlet /></div>
                <div style={{minWidth: "250px", flex: "1 0 250px"}}><InfoSidebar /></div>
        </Container>
        <CopyrightFooter />
        <ScrollRestoration />
    </div>;
};
import { Link } from "react-router-dom";
import { cssJoin } from "../../util/Formatting";

interface IEasyLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement>
{
    noStyle?: boolean;
    stretch?: boolean;
    linkColor?: LinkColor;
}

export enum LinkColor {
    Primary = "link-primary",
    Secondary = "link-secondary",
    Success = "link-success",
    Danger = "link-danger",
    Warning = "link-warning",
    Info = "link-info",
    Light = "link-light",
    Dark = "link-dark"
}

export const EasyLink: React.FC<IEasyLinkProps> = (props) => {
    const { href, noStyle, stretch, linkColor, children, ...rest } = props;
    const linkProps = { ...rest, className: cssJoin(props.className, noStyle ? "text-decoration-none" : null, stretch ? "stretched-link" : null, linkColor ? linkColor : LinkColor.Dark )};
    return props.href?.charAt(0)==='/' ? <Link to={href ?? ""} {...linkProps}>{children}</Link> : <a href={href} {...linkProps} target="_blank" rel="noopener noreferrer">{children}</a>;
};
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useCallback } from "react";
import { NavItem, NavLink } from "reactstrap";
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from "../../util/Auth";

export const LoginItem = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    
    const handleLogin = useCallback(() => {
        instance.loginRedirect(loginRequest);
    }, [instance]);

    const handleLogout = useCallback(() => {
        instance.logoutPopup();
    }, [instance]);

    return <NavItem>
        {isAuthenticated ? 
            <NavLink style={{cursor: "pointer" }} onClick={handleLogout}>Log Out</NavLink> :
        inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect ?
            <NavLink style={{cursor: "pointer" }} onClick={handleLogin}>Log In</NavLink> : null }
    </NavItem>;
};
